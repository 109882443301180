import styled from 'styled-components';

import UI from '#front-credpago';

export const LayoutWrapper = styled.div`
  overflow-x: hidden;

  @media ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    h1 {
      font-size: calc(5px + 5vw);
      margin-bottom: 30px;
    }
  }

  @media ${UI.globals.mediaqueries.xs} {
    h1 {
      font-size: calc(5px + 7vw);
      margin-bottom: 30px;
    }
  }
`;
