import styled from 'styled-components';

import UI from '#front-credpago';

export const RebrandingWrapper = styled.div`
  background-color: #f56738;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 58px;
  width: 100%;
  position: fixed;
  z-index: 2000;
  @media ${UI.globals.mediaqueries.xs} {
    max-height: 112px;
  }
  @media ${UI.globals.mediaqueries.sm} {
    max-height: 76px;
  }

  .span-link-loft {
    margin: 0px 10px;
  }

  .href-link-loft {
    color: #ffffff;
    text-decoration: underline;
  }
`;