import { Link } from 'gatsby';
import React from 'react';

import UI from '#front-credpago';

import { LogoLoftCredpago } from '../logos/LogoLoftCredpago';
import { Container } from '../partials/style';
import ReclameAqui from './reclameAqui';
import { FooterWrapper, SitemapWrapper } from './style';

const Footer = () => {
  return (
    <FooterWrapper id="footer">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12, sm: 12, md: 9 }}>
            <SitemapWrapper>
              <div>
                <UI.Title level={6} fontWeight={900}>
                  Sobre a Garantia CredPago
                </UI.Title>
                <ul>
                  <li>
                    <Link to="/aluguel-sem-fiador#quanto-custa">Quanto Custa</Link>
                  </li>
                  <li>
                    <Link to="/aluguel-sem-fiador#como-funciona">Como Funciona</Link>
                  </li>
                </ul>
              </div>
              <div>
                <UI.Title level={6} fontWeight={900}>
                  Sobre a empresa
                </UI.Title>
                <ul>
                  <li>
                    <Link to="/nossa-historia">Nossa História</Link>
                  </li>
                  <li>
                    <Link to="/nossa-historia#missao-visao-valores">Missão, visão e valores</Link>
                  </li>
                  <li>
                    <a href="/embaixadores">Embaixadores</a>
                  </li>
                  <li>
                    <Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
                  </li>
                </ul>
              </div>
              <div>
                <UI.Title level={6} fontWeight={900}>
                  Ajuda | FAQ
                </UI.Title>
                <ul>
                  <li>
                    <a
                      data-testid="credsign"
                      href="https://go-sign.credpago.com/validar/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Validar Certificado CredSign
                    </a>
                  </li>
                  <li>
                    <a
                      data-testid="new-credsign"
                      href="https://new-credsign.credpago.com/valid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Validar Certificado Novo CredSign
                    </a>
                  </li>
                  <li>
                    <Link to="/politica-de-privacidade">Política de Privacidade</Link>
                  </li>
                  <li>
                    <Link to="/politica-de-cookies">Política de Cookies</Link>
                  </li>
                  <li>
                    <a href="/atendimento">Perguntas Frequentes</a>
                  </li>
                </ul>
              </div>
              <div>
                <UI.Title level={6} fontWeight={900}>
                  Fale com a gente
                </UI.Title>
                <ul>
                  <li>
                    <a href="/atendimento">Entre em contato</a>
                  </li>
                  <li>
                    <Link to="/cadastre-se">Quero me cadastrar</Link>
                  </li>
                  <li>
                    <a href="mailto:imprensa@credpago.com" style={{ textDecoration: 'underline' }}>
                      imprensa@credpago.com
                    </a>
                    <br />
                    Atendimento exclusivo aos profissionais de imprensa
                  </li>
                </ul>
              </div>
              <div>
                <UI.Title level={6} fontWeight={900}>
                  Acessos
                </UI.Title>
                <ul>
                  <li>
                    <a href="https://inquilino.credpago.com/" target="_blank" rel="noopener noreferrer">
                      Área do Inquilino
                    </a>
                  </li>
                  <li>
                    <a href="https://credpago.com/login.php#" target="_blank" rel="noopener noreferrer">
                      Área da Imobiliária
                    </a>
                  </li>
                  <li>
                    <Link to="/download-da-marca">Download da Marca</Link>
                  </li>
                </ul>
              </div>
            </SitemapWrapper>
          </UI.Col>
          <UI.Col responsive={{ xs: 12, sm: 12, md: 3 }}>
            <UI.Title level={6} fontWeight={900}>
              Redes Sociais
            </UI.Title>
            <UI.SocialNetworks type="new" className="social-networks" maxWidth={150} />
            <ReclameAqui />
          </UI.Col>
        </UI.Row>
      </Container>
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 8 }}>
            <LogoLoftCredpago />
          </UI.Col>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 4 }}>
            <div className="copyright">
              <p>35.602.536/0001-61 — 25.027.928.0001-90</p>
              <small>CredPago © {new Date().getFullYear()}. Todos os direitos reservados. </small>
            </div>
          </UI.Col>
        </UI.Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
