import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { paddingSides, colors, fonts } = globals;

export const FooterWrapper = styled.footer`
  background: #f1f1f1;
  color: #7d7d7d;
  padding: 20px 0 60px;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    padding: 20px 0 150px;
    text-align: center;
  }

  h6 {
    margin-bottom: 1em;

    @media ${UI.globals.mediaqueries.md},
      ${UI.globals.mediaqueries.lg},
      ${UI.globals.mediaqueries.xl} {
      font-size: 16px;
    }
  }

  .copyright {
    text-align: right;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      text-align: center;
    }

    p {
      color: ${colors.darkBlue};
    }
  }

  .social-networks {
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin: 0 auto;
    }
  }

  .logo-footer {
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      max-width: 250px;
    }
  }

  @keyframes go-right {
    0% {
      transform: translateX(-200px) rotate(45deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes cursor {
    0% {
      opacity: 1;
    }
    1% {
      opacity: 0.2;
    }
  }

  @keyframes brightness {
    0% {
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    }
    1% {
      box-shadow: 0px 0px 5px 2px rgba(0, 236, 250, 0.5);
    }
  }

  #on-the-road {
    @keyframes go-right {
      0% {
        transform: translateX(-200px) rotate(45deg);
      }
      90% {
        transform: rotate(45deg);
      }
      100% {
        transform: translateX(0px);
      }
    }
    background: rgb(0, 91, 203);
    background: linear-gradient(
      68deg,
      rgba(0, 91, 203, 1) 0%,
      rgba(0, 29, 100, 1) 100%
    );
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    .countdown {
      display: grid;
      grid-template-columns: 4fr 1fr 4fr 4fr;
      justify-items: center;
      align-items: center;
      color: white;
      padding: 0em;
      margin: 0 auto;
      max-width: 1200px;
      gap: 1rem;
      @media screen and (min-width: 1024px) {
        padding-left: 60px;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 100%;
        padding: 1em;
        grid-gap: 1em;
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: 55% 45% 100%;
        padding: 0.5em;
        grid-gap: 4px;
        > :nth-child(1){
          justify-self: end;
        }
        > :nth-child(2){
          justify-self: start;
          margin-left: 20px;
        }
        > :nth-child(3){
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
        }
      }
      h2 {
        font-family: "Uni Neue";
        font-style: italic;
        font-weight: 800;
        font-size: 22px;
        line-height: 16px;
        justify-self: end;
        text-align: end;
        svg {
          margin-right: 10px;
          animation: go-right 1s;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
          justify-self: center;
          text-align: center;
          line-height: 1.1;
        }
        @media screen and (max-width: 1024px) {
          position: relative;
          margin: 0;
        }
        span span {
          color: #ffcb05;
          text-transform: uppercase;
      }
        }
        span div {
          font-size: 16px;
          font-weight: 500;
        }
      }
      a {
        font-size: 1em;
        padding: 9px 15px;
        @media screen and (max-width: 1024px) {
          position: relative;
          font-size: 14px;
          padding: 9px 15px;
        }
        @media screen and (max-width: 576px) {
          margin-top: 0;
          padding: 0;
        }
      }
      .btn-footer {
        justify-self: start;
        @media screen and (max-width: 768px) {
          justify-self: center;
          position: relative;
          height: 33px;
          margin-top: 3px;
        }
        @media screen and (max-width: 576px) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
        }
      }
    }
    .tooltip {
      display: inline;
      position: relative;
    }
    @media screen and (min-width: 576px) {
      .tooltip:hover:before {
        border: solid;
        border-color: #001c4a transparent;
        border-width: 6px 6px 0px 6px;
        bottom: 37px;
        content: "";
        left: 10%;
        position: absolute;
        z-index: 99;
      }
      .tooltip:hover:after {
        background: #001c4a;
        bottom: 43px;
        border-radius: 5px;
        color: #fff;
        content: attr(data-tooltip);
        left: 0;
        padding: 5px 15px;
        position: absolute;
        z-index: 98;
        width: 100%;
        font-size: 14px;
        text-transform: none;
        font-weight: 300;
        line-height: 1.2;
      }
    }

    .airplane {
      svg {
        animation: go-right 1s;
      }
      span {
        animation: cursor 2s infinite;
      }
    }
  }
`;

export const FooterLine = styled.div`
  text-align: center;
  background: #52627b;
  display: block;
  padding: 10px 15px;
  color: white;
`;

export const FooterContactsWrapper = styled.div`
  border-top: 1px solid #1e4d74;
  margin-top: 60px;
  padding-top: 25px;
  padding-bottom: 40px;

  @media ${UI.globals.mediaqueries.xs} {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  @media ${UI.globals.mediaqueries.sm} {
    margin-top: 20px;
  }
`;

export const FooterContacts = styled.div`
  max-width: ${globals.containerMaxWidth};
  margin: 0 auto;
  padding: 0 ${paddingSides};
  display: flex;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    flex-direction: column;
  }

  .social-network-list {
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
`;

export const FooterLinksWrapper = styled.div`
  max-width: ${globals.containerMaxWidth};
  padding: 1em ${paddingSides} 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 15px;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    grid-template-columns: 1fr;
  }

  h6 {
    text-transform: uppercase;
    font-weight: 800;
  }
`;

export const SiteMap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr;
  grid-gap: 15px;

  @media ${UI.globals.mediaqueries.xs} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  h6 {
    margin-bottom: 5px;
  }

  .mainLinks {
    text-transform: uppercase;

    a {
      font-weight: 400;
    }

    h6 {
      @media ${UI.globals.mediaqueries.xs} {
        display: none;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: white;
      font-weight: 300;
      text-decoration: none;
      font-size: 0.9em;
      transition: color 0.2s;

      &:hover {
        color: ${colors.lightBlue};
      }
    }
  }
`;

export const Newsletter = styled.div`
  h6 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const NewsletterForm = styled.form`
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-gap: 15px;
  margin-top: 30px;

  @media ${UI.globals.mediaqueries.xs} {
    grid-template-columns: 1fr;
  }

  .feedbackWrapper {
    overflow: hidden;
    max-height: 0px;
    display: block;
  }
`;

export const LogosCredpagoWrapper = styled.div`
  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    text-align: center;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0 15px 0 0;
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        width: 1px;
        height: 90%;
        display: block;
        background: white;
        position: absolute;
        right: 0;
        opacity: 0.6;
        top: 50%;
        transform: translateY(-50%);
      }

      & + li {
        margin-left: 15px;
      }

      &:last-child {
        padding-right: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  img {
    margin: 0;
    padding: 0;
  }
`;

export const ContatosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  width: 100%;
  max-width: 620px;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    grid-template-columns: 1fr;
  }
`;

export const ContatoWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 40px minmax(0, 1fr);
  color: white;
  font-style: italic;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    justify-self: center;
  }

  svg > * {
    fill: white;
  }

  h5 {
    margin: 0 !important;
    padding: 0;
  }

  .social-networks {
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin: 0.6em auto 0;
    }
  }
`;

export const SitemapWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.3fr repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
  font-family: ${fonts.headings};

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    grid-template-columns: minmax(0, 1fr);
  }

  ul {
    margin: 0.6em 0 0 0;
    padding: 0;
    list-style: none;

    li {
      padding: 4px 0;
    }

    a {
      color: #7d7d7d;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ReclameAquiWrapper = styled.div`
  text-align: center;
  margin-top: 1.4em;

  #ra-widget-reputation {
    margin: 0 auto;

    @media ${UI.globals.mediaqueries.md}, ${UI.globals.mediaqueries.lg}, ${UI.globals.mediaqueries.xl} {
      margin: 0;
    }
  }

  .link-votacao {
    margin-top: 1em;
    display: block;

    img {
      max-width: 420px;
      width: 100%;
      height: auto;
    }
  }
`;
