import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import UI from '#front-credpago';

import ChatButton from '../chatButton';
import Footer from '../footer/index';
import Header from '../header/index';
import ImobiliariasParceiras from '../partials/imobiliariasParceiras';
import { LayoutWrapper } from './style';

const Layout = ({ children, internal, displayImobsParceiras, hasChatButton = false, style }) => {
  return (
    <LayoutWrapper style={style}>
      <UI.GlobalStyle />
      <Helmet>
        <meta name="theme-color" content={UI.globals.colors.brandLightBlue} />
        <meta name="msapplication-navbutton-color" content={UI.globals.colors.brandLightBlue} />
        <meta name="apple-mobile-web-app-status-bar-style" content={UI.globals.colors.brandLightBlue} />
        <meta name="apple-mobile-web-app-title" content="credpago-site" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
        <link rel="icon" type="ico" sizes="16x16" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#113e64" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://snap.licdn.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="stylesheet" href="/styles/fonts.css" />

        <meta name="adopt-website-id" content="78ef1068-840c-4ada-af0d-2d7f81f20890" />
        <style>
          {`
        @media (max-width: 768px) {
          .adopt-controller-button {
            bottom: 120px!important;
            left: 5px!important;
            width: 50px !important!important;
            height: 50px !important!important;
          }
        }
        `}
        </style>
        <script
          src="//tag.goadopt.io/injector.js?website_code=78ef1068-840c-4ada-af0d-2d7f81f20890"
          className="adopt-injector"
        ></script>
      </Helmet>
      <Header />
      <div>
        <main style={{ paddingTop: '88px' }}>
          {children}
          {displayImobsParceiras && <ImobiliariasParceiras />}
        </main>
        <Footer />
        {/* <FixedBar /> */}
        {hasChatButton && <ChatButton />}
      </div>
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  internal: false,
  displayImobsParceiras: true,
};

export default Layout;
