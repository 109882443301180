// import { lighten, darken } from 'polished'
import styled from 'styled-components';

import UI from '#front-credpago';

import BgLinhas from '../../images/linhas-loft.png';
import globals from '../globals';

const { containerMaxWidth, fonts, colors } = globals;

export const Container = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  align-self: center;
  position: relative;
`;

export const FacilidadesWrapper = styled.section`
  h2 {
    margin-top: 1em;
    max-width: 800px;
    margin: 0.7em auto;
  }
`;

export const ListaFacilidades2 = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;
  font-family: ${fonts.default};
  grid-template-columns: 1fr 1fr;
  text-align: left;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    grid-template-columns: 1fr;
  }

  @media ${UI.globals.mediaqueries.md} {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5em;
    grid-template-areas: 'seguro credpago360';
  }

  & > * {
    border-radius: 25px;
    min-height: 280px;
    padding: 42px;
    position: relative;
    overflow: hidden;

    .logo {
      width: 169px;
      height: 86px;
    }

    p {
      line-height: 1.7;
      max-width: 200px;
      margin: 1.9em 0;

      @media ${UI.globals.mediaqueries.md} {
        line-height: 1.5;
      }

      &.closed {
        @media ${UI.globals.mediaqueries.md} {
          span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      &.opened {
        @media ${UI.globals.mediaqueries.md} {
          line-height: 1.5;
        }
      }

      @media ${UI.globals.mediaqueries.md} {
        line-height: 1.5;
      }
    }

    .link-more {
      display: none;

      @media ${UI.globals.mediaqueries.md} {
        display: inline-block;
      }
    }

    p,
    button {
      z-index: 1;
    }

    .img-wrapper {
      position: absolute;
      height: auto;
      display: block;
      pointer-events: none;
      z-index: 0;
    }
  }

  button:not(.button--is-inverted) {
    background: #f5f5f5;
  }

  .link-more {
    color: ${UI.globals.colors.brandDarkBlue};
    text-decoration: underline;
  }

  .facilidade-box {
    box-shadow: 0px 3px 6px #00000029;
  }

  .credpago360 {
    background: #f8f8f8;

    .logo {
      width: 170px;
    }

    .text-wrapper {
      max-width: 180px;
      margin-right: 0;
      margin-left: auto;

      @media ${UI.globals.mediaqueries.md} {
        margin-left: 0px;
      }
    }

    p {
      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 90%;
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 90%;
      }
    }

    .img-wrapper {
      width: 320px;
      left: 30px;
      bottom: 0px;

      @media ${UI.globals.mediaqueries.lg} {
        width: 51%;
        bottom: 0px;
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 60%;
        bottom: 0px;
        left: 147px;
      }

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 40%;
        left: -35px;
      }
    }
  }

  .seguro {
    .text-wrapper {
      @media ${UI.globals.mediaqueries.xl} {
        max-width: 45%;
        margin-right: 0;
        margin-left: auto;
      }
    }

    background: #f6f6f6;
    grid-area: auto;
    overflow: inherit;

    @media ${UI.globals.mediaqueries.md} {
      grid-area: seguro;
      overflow: hidden;
    }

    .logo {
      width: 167px;

      @media ${UI.globals.mediaqueries.md} {
        width: 140px;
      }
    }

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      grid-area: auto / auto / auto / auto;
      padding-bottom: 180px;
    }

    p {
      max-width: 600px;

      @media ${UI.globals.mediaqueries.md} {
        max-width: 48%;
      }

      @media ${UI.globals.mediaqueries.lg} {
        max-width: 42%;
      }
    }

    .img-wrapper {
      @media ${UI.globals.mediaqueries.xl} {
        width: 641px;
        bottom: 10px;
        left: 4%;
      }

      width: 580px;
      left: -126px;
      bottom: 30px;

      @media ${UI.globals.mediaqueries.lg} {
        left: 45%;
        overflow: hidden;
      }

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 420px;
        top: auto;
        bottom: 0;
        transform: translateY(0);
        left: -12px;
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 470px;
        left: 45%;
        bottom: 70px;
      }
    }
  }
`;

export const ListaFacilidades = styled.div`
  display: grid;
  grid-gap: 20px;
  font-family: ${fonts.default};
  grid-template-columns: 1fr 1fr;
  text-align: left;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    grid-template-columns: 1fr;
  }

  @media ${UI.globals.mediaqueries.md} {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5em;
    grid-template-areas:
      'simulador multipgto'
      'credsign';
  }

  & > * {
    border-radius: 25px;
    min-height: 280px;
    padding: 42px;
    position: relative;
    overflow: hidden;

    .logo {
      width: 169px;
      height: 86px;
    }

    p {
      line-height: 1.7;
      max-width: 200px;
      margin: 1.9em 0;

      @media ${UI.globals.mediaqueries.md} {
        line-height: 1.5;
      }

      &.closed {
        @media ${UI.globals.mediaqueries.md} {
          span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      &.opened {
        @media ${UI.globals.mediaqueries.md} {
          line-height: 1.5;
        }
      }

      @media ${UI.globals.mediaqueries.md} {
        line-height: 1.5;
      }
    }

    .link-more {
      display: none;

      @media ${UI.globals.mediaqueries.md} {
        display: inline-block;
      }
    }

    p,
    button {
      z-index: 1;
    }

    .img-wrapper {
      position: absolute;
      height: auto;
      display: block;
      pointer-events: none;
      z-index: 0;
    }
  }

  button:not(.button--is-inverted) {
    background: #f5f5f5;
  }

  .link-more {
    color: ${UI.globals.colors.brandDarkBlue};
    text-decoration: underline;
  }

  .facilidade-box {
    box-shadow: 0px 3px 6px #00000029;
  }

  .simulador {
    background: #f8f8f8;

    @media ${UI.globals.mediaqueries.md} {
      grid-area: simulador;
    }

    .logo {
      max-width: 112px;
    }

    .img-wrapper {
      width: 300px;
      right: -90px;
      top: 40%;
      transform: translateY(-50%);

      @media ${UI.globals.mediaqueries.md} {
        width: 230px;
        right: -60px;
      }

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 200px;
        right: -50px;
      }
    }

    p {
      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
        width: 50%;
      }
    }
  }

  .multiPagamento {
    background: #f6f6f6;
    @media ${UI.globals.mediaqueries.md} {
      grid-area: multipgto;
    }

    .logo {
      width: 122px;

      @media ${UI.globals.mediaqueries.md} {
        width: 137px;
      }
    }

    .img-wrapper {
      width: 150px;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);

      @media ${UI.globals.mediaqueries.md} {
        width: 130px;
      }

      @media ${UI.globals.mediaqueries.xs} {
        width: 110px;
      }
    }

    p {
      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
        width: 50%;
      }
    }
  }

  .credSign {
    background: #f6f6f6;

    @media ${UI.globals.mediaqueries.md} {
      grid-area: credsign;
    }

    .logo {
      width: 170px;
    }

    .text-wrapper {
      max-width: 180px;
      margin-right: 0;
      margin-left: auto;
    }

    p {
      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 90%;
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 90%;
      }
    }

    .img-wrapper {
      width: 260px;
      left: 0;
      bottom: 30px;

      @media ${UI.globals.mediaqueries.lg} {
        width: 51%;
        bottom: 10px;
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 72%;
        bottom: 20px;
      }

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 40%;
        bottom: 10px;
      }
    }
  }

  .seguroResidencial {
    background: #f6f6f6;
    grid-area: auto / auto / span 1 / span 3;
    overflow: inherit;

    @media ${UI.globals.mediaqueries.md} {
      grid-area: seguro;
      overflow: hidden;
    }

    .logo {
      width: 167px;

      @media ${UI.globals.mediaqueries.md} {
        width: 140px;
      }
    }

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      grid-area: auto / auto / auto / auto;
      padding-bottom: 180px;
    }

    p {
      max-width: 600px;

      @media ${UI.globals.mediaqueries.md} {
        max-width: 48%;
      }

      @media ${UI.globals.mediaqueries.lg} {
        max-width: 42%;
      }
    }

    .img-wrapper {
      width: 770px;
      right: -66px;
      bottom: -66px;

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        width: 420px;
        top: auto;
        bottom: 0;
        transform: translateY(0);
      }

      @media ${UI.globals.mediaqueries.md} {
        width: 470px;
        right: -230px;
        bottom: 35px;
      }
    }
  }
`;

export const PersonasWrapper = styled.section`
  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    padding-top: 1em;
  }

  @media ${UI.globals.mediaqueries.lg}, ${UI.globals.mediaqueries.xl} {
    padding-top: 5em;
  }

  h2 {
    max-width: 1000px;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin-bottom: 0.4em;
    }
  }

  .col > .col-inner {
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  h5 {
    margin-bottom: 0.6em;
  }

  h6 {
    margin-bottom: 1em;
    color: #7d7d7d;
  }

  ul,
  p {
    font-family: ${fonts.default};
    color: #7d7d7d;
    font-size: 0.95em;
  }

  ul {
    margin-left: 0;
    padding-left: 1em;
    list-style: none;

    li {
      &:before {
        content: '•';
        font-size: 0.8em;
        padding-right: 0.4em;
      }
    }
  }

  .img-wrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  .tab-proprietarios .img-wrapper {
    max-width: 230px;
  }
`;

export const BlocoBtgWrapper = styled.section`
  margin-top: 8em;
  position: relative;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    padding-bottom: 2em;
    margin-top: 3em;
  }

  @media ${UI.globals.mediaqueries.md} {
    display: none;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: url(${BgLinhas}) no-repeat center bottom -140px;
    background-size: 110% 100%;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      background-size: 100% auto;
      background: url('') no-repeat center bottom -140px;
    }

    @media ${UI.globals.mediaqueries.md} {
      top: 100px;
    }
  }

  @media ${UI.globals.mediaqueries.xs} {
    .text-title {
      text-align: center !important;
    }
  }

  .text-wrapper {
    text-align: justify;

    h3 {
      max-width: 700px;
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 0.5em;
    }

    h6 {
      margin-bottom: 2em;
    }

    p {
      color: ${colors.darkBlue};
      line-height: 1.8;
      margin-bottom: 2em;
    }

    h6,
    p,
    .logo-wrapper {
      max-width: 420px;
      margin-right: 0;
      margin-left: auto;
    }

    .logo-wrapper .logo {
      width: 80%;
      height: auto;
    }
  }

  .focused {
    background: yellow;
  }

  .picture-wrapper {
    margin-top: 10em;
    transform: scale(1.4) translateX(70px);

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin-top: 1em;
      transform: scale(1.4) translateX(50px);
    }

    .gatsby-image-wrapper {
      pointer-events: none;
    }
  }
`;

export const DepoimentosWrapper = styled.div`
  padding-bottom: 4em;

  h3 {
    padding-top: 2em;
    padding-bottom: 0.6em;
  }

  @media ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    .title {
      padding-top: 1em;
    }
  }

  @media ${UI.globals.mediaqueries.xs} {
    display: none;
  }

  .swiper-buttons-nav {
    position: absolute;
    top: 50%;
    z-index: 1000;

    &:after {
      display: none;
    }

    button svg {
      max-width: 8px;
    }

    &.swiper-button-depo-prev {
      left: 10%;

      @media ${UI.globals.mediaqueries.lg} {
        left: 0;
      }
    }

    &.swiper-button-depo-next {
      right: 10%;

      @media ${UI.globals.mediaqueries.lg} {
        right: 0;
      }
    }

    &.swiper-button-disabled {
      filter: grayscale(1);
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .swiper-slide {
    filter: grayscale(1);
    opacity: 0.7;
    transition: all 0.2s;

    &.swiper-slide-prev {
      transform: translateX(130px);

      @media ${UI.globals.mediaqueries.lg} {
        transform: translateX(70px);
      }
    }

    &.swiper-slide-next {
      transform: translateX(-130px);

      @media ${UI.globals.mediaqueries.lg} {
        transform: translateX(-70px);
      }
    }

    &.swiper-slide-active {
      opacity: 1;
      filter: grayscale(0);
    }
  }
`;

export const DepoimentoWrapper = styled.div`
  background: white;
  max-width: 215px;
  margin: 10px auto;
  border-radius: 35px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 30px;
  height: 460px !important;
  display: block;

  @media ${UI.globals.mediaqueries.md} {
    max-width: 200px;
  }

  img {
    border-radius: 12px;
    margin-bottom: 1.3em;
  }

  h5 {
    color: ${colors.lightBlue};
  }

  h6 {
    color: #7d7d7d;
    margin: 0.6em 0;
  }

  blockquote {
    padding: 0;
    color: #7d7d7d;
    font-size: 0.9em;
    margin-left: 0;
    position: relative;
    padding: 0 1.4em;

    &:before,
    &:after {
      position: absolute;
      font-size: 2em;
      font-family: 'Times New Roman', Times, serif;
      font-weight: bold;
      color: #ccc;
    }

    &:before {
      content: '\“';
      left: 0;
      top: -10px;
    }

    &:after {
      content: '\”';
      right: 0;
      bottom: -10px;
    }

    p {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export const ImobsParceirasWrapper = styled.section`
  background: ${colors.lightBlue};
  color: white;
  text-align: center;
  padding-top: 42px;
  padding-bottom: 42px;
  position: relative;

  h4 {
    color: white;
    padding-bottom: 1.2em;
  }

  .swiper-container {
    @media ${UI.globals.mediaqueries.xs} {
      img {
        width: 90px !important;
      }
    }
  }

  .swiper-buttons-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-4px);
    z-index: 1100;
    cursor: pointer;

    &:after {
      display: none;
    }

    &.swiper-button-prev {
      left: 12px;
    }

    &.swiper-button-next {
      right: 12px;
    }

    svg {
      width: 13px;
      height: auto;
      display: block;

      * {
        fill: white;
      }
    }

    @media ${UI.globals.mediaqueries.xs} {
      svg {
        width: 11px !important;
      }

      &.swiper-button-prev {
        left: 0px !important;
      }

      &.swiper-button-next {
        right: 0px !important;
      }
    }
  }
`;

export const QuestionsWrapper = styled.div`
  padding-bottom: 4em;

  @media ${UI.globals.mediaqueries.md} {
    padding-bottom: 2em;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    padding-bottom: 1.4em;
  }

  summary {
    transition: all 0.2s;
  }

  .focused {
    animation: shake 1s 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    summary {
      background: #fafdce;
    }
  }

  .question-wrapper {
    position: relative;
    padding: 15px 0;
    transition: all 0.2s;

    &:before {
      content: '';
      width: calc(100% + 30px);
      height: 100%;
      display: block;
      position: absolute;
      left: -15px;
      top: 0;
      z-index: -1;
      transition: background 2s;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

export const QuestionsHeader = styled.div`
  .header-questions {
    padding-left: 40px;
    padding-right: 40px;

    .text-wrapper {
      align-self: center;
    }

    .pic-wrapper {
      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        display: none;
      }
    }

    h1 {
      max-width: 590px;
      line-height: 1.1;

      & + p {
        margin-top: 2em;
      }
    }

    p {
      max-width: 666px;
    }
  }
`;

export const CustomAccordion = styled(UI.AccordionItem)`
  margin-top: 1.4em;

  .video {
    margin: 16px 0;
  }

  @media ${UI.globals.mediaqueries.xs} {
    .video {
      width: 100%;

      iframe {
        width: 100%;
      }
    }

    .multipagamento-table {
      tbody {
        tr {
          display: flex;
          flex-direction: column;

          td {
            & + td {
              margin-top: 16px;
            }

            img {
              max-width: initial !important;
              width: auto;
            }

            > ul {
              padding-left: 26px;
            }
          }
        }
      }
    }
  }
`;

export const MidiaWrapper = styled.section`
  .swiper-wrapper {
    margin-top: 2em;
    margin-bottom: 2em;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  .swiper-pagination {
    text-align: center;
    min-height: 8px;

    .swiper-pagination-bullet-active {
      background: black;
    }
  }
`;

export const SlideText = styled.div`
  max-width: 220px;

  p {
    font-size: 1em;
    line-height: 1.5;
    color: #888;
  }

  a {
    color: ${colors.lightBlue};
  }

  .logo-wrapper {
    width: 100%;
    height: 70px;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      grid-template-columns: 55px;
    }

    .logo {
      object-fit: scale-down;
      height: 70px;
      width: 100%;
      max-width: 140px;

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        height: 55px;
        max-width: 120px;
      }
    }
  }
`;
