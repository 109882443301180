import 'swiper/swiper.min.css';

import React, { useEffect, useRef } from 'react';
import Swiper, { Autoplay, Navigation } from 'swiper';

import UI from '#front-credpago';

import { ReactComponent as ArrowLeft } from '../../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';
import LogoAnage from '../../images/imobiliarias/anage.png';
import LogoApolar from '../../images/imobiliarias/apolar.png';
import LogoFranciosi from '../../images/imobiliarias/franciosi.png';
import LogoFriasNeto from '../../images/imobiliarias/friasNeto.png';
import LogoIbagy from '../../images/imobiliarias/ibagy.png';
import LogoLelo from '../../images/imobiliarias/lelo.png';
import LogoPiramid from '../../images/imobiliarias/piramid.png';
import LogoPortalSp from '../../images/imobiliarias/portalSp.png';
import { Container, ImobsParceirasWrapper } from './style';

Swiper.use([Navigation, Autoplay]);

const ImobsParceiras = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    //add necessary parameters required by checking the offical docs of swiper
    swiperRef.current = new Swiper('.swiper-container-parceiras', {
      slidesPerView: 2,
      loop: true,
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        575: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        991: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1400: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
      },
    });
  }, []);

  return (
    <ImobsParceirasWrapper id="imobiliarias-parceiras">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12 }}>
            <UI.Title level={4}>
              <strong>Imobiliárias parceiras</strong> em todo o Brasil
            </UI.Title>

            <div className="swiper-container swiper-container-parceiras">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoAnage} alt="Anagê Imóveis" width="103" height="45" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoApolar} alt="Apolar" width="122" height="37" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoFranciosi} alt="Franciosi" width="139" height="39" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoFriasNeto} alt="Frias Neto" width="126" height="40" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoIbagy} alt="Ibagy" width="116" height="46" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoLelo} alt="Lelo" width="120" height="43" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoPiramid} alt="Piramid" width="119" height="45" />
                </div>
                <div className="swiper-slide">
                  <img decoding="async" loading="lazy" src={LogoPortalSp} alt="Portal SP" width="100" height="58" />
                </div>
              </div>
              <div className="swiper-buttons-nav swiper-button-prev">
                <ArrowLeft />
              </div>
              <div className="swiper-buttons-nav swiper-button-next">
                <ArrowRight />
              </div>
            </div>
          </UI.Col>
        </UI.Row>
      </Container>
    </ImobsParceirasWrapper>
  );
};

export default ImobsParceiras;
