import React from 'react';
import { Helmet } from 'react-helmet';

import { ReclameAquiWrapper } from './style';

const ReclameAqui = () => {
  return (
    <ReclameAquiWrapper>
      <div id="reputation-ra">
        <Helmet>
          <script
            type="text/javascript"
            id="ra-embed-reputation"
            src="https://s3.amazonaws.com/raichu-beta/selos/bundle.js"
            data-id="QjR6QWk5QU1Od001clZrRDpjcmVkcGFnbw=="
            data-target="reputation-ra"
            data-model="2"
          ></script>
        </Helmet>
      </div>
    </ReclameAquiWrapper>
  );
};

export default ReclameAqui;
