import useEventListener from '@use-it/event-listener';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import UI from '#front-credpago';
import RebrandingCommunication from '../rebrandingCommunication';
import { HeaderWrapper } from './style';

const HeaderComponent = () => {
  const [bs, updateBs] = useState(false);
  const [activeClass, updateClass] = useState('');

  useEventListener('scroll', () => {
    let currentPosition = window.pageYOffset;
    let isActive = currentPosition > 30;
    let bsCurrent = isActive ? 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px' : 'rgba(0, 0, 0, 0) 0px 2px 10px 0px';

    if (isActive) {
      if (bs !== bsCurrent) {
        updateBs(bsCurrent);

        updateClass('active');
      }
    } else {
      updateClass('');
      updateBs(bsCurrent);
    }
  });

  const menu = [
    {
      label: 'Produtos',
      href: '/#produtos-credpago',
      children: [
        {
          label: 'Aluguel sem fiador',
          href: '/aluguel-sem-fiador#como-funciona',
        },
        {
          label: 'Credsign',
          href: `${process.env.GATSBY_SELF_URL}/credsign`,
          isExternalLink: true,
        },
        {
          label: 'Seguro Residencial',
          href: `${process.env.GATSBY_SELF_URL}/seguro`,
          isExternalLink: true,
        },
      ],
    },
    {
      label: 'Aluguel sem fiador',
      href: '/aluguel-sem-fiador',
    },
    {
      label: 'Blog',
      href: 'https://blog.credpago.com.br/',
    },
  ];

  const acessarPlataforma = () => {
    const windowObjExists = typeof window !== 'undefined';
    if (windowObjExists) {
      window.location = process.env.GATSBY_CP_HOME_PLATAFORMA + '/login.php';
    }
  };

  return (
    <HeaderWrapper className={activeClass}>
      <RebrandingCommunication />
      <UI.Header
        style={{
          position: 'fixed',
          transition: 'all .2s',
          zIndex: 2000,
          boxShadow: bs,
          background: 'rgba(255,255,255,0.91)',
        }}
        onLogoClick={() => navigate('/')}
        onSignUp={() => navigate('/cadastre-se')}
        signUpText="Novos Parceiros"
        signInText="Entrar"
        textBelowSignIn="Inquilinos e Imobiliárias"
        onOpenService={() => navigate('/atendimento/quero-ser-cliente')}
        onOpenServiceText="Atendimento"
        navConfig={{
          component: Link,
          hrefProp: 'to',
        }}
        menu={menu}
        onEnter={acessarPlataforma}
      />
    </HeaderWrapper>
  );
};

HeaderComponent.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderComponent.defaultProps = {
  siteTitle: ``,
};

export default HeaderComponent;
