import './chat.css';

import React, { useEffect } from 'react';

const ChatButton = () => {
  useEffect(() => {
    // Webchat Loaded Callback
    window.olosWebChatInit = function (Botkit) {
      Botkit.boot({
        id: Math.floor(Math.random() * 2147483647).toString(),
        name: 'Olos Webchat',
      });
    };
    // Load Webchat API
    (function (v, cid, gid, cstm, lv, ext) {
      var id = 'oloswcdl-script',
        s = 'script',
        d = document;
      var js,
        fjs = d.getElementsByTagName(s)[0];
      var host = 'https://olosbotsolutionstatic.blob.core.windows.net/webchat/botkit';
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        host +
        '/v' +
        v +
        '/main.bundle.js?cid=' +
        cid +
        '&gid=' +
        gid +
        '&version=' +
        v +
        '&custom=' +
        cstm +
        '&lv=' +
        lv +
        '&ext=' +
        ext +
        '&use_local_history=1&keep_session=1';
      fjs.parentNode.insertBefore(js, fjs);
    })('3.4.1', '1638798688277', '0036c-6e-414-a3b-d7e65', 'credpago', 'br001', '');

    return function cleanUp() {
      const script = document.getElementById('oloswcdl-script');

      if (script) {
        script.remove();
      }
    };
  }, []);

  return <div id="oloswcdl-root"></div>;
};

export default ChatButton;
