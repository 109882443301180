import { Divider, Flex, Image } from '@loft/react-credpago';
import { logo } from '@loft/tokens-credpago';
import { logo as logoLoft } from '@loft/tokens-loft';
import React from 'react';

export const LogoLoftCredpago = () => {
  return (
    <Flex>
      <Image src={logoLoft.primary.medium.svg} />
      <Divider orientation="vertical" weight="medium" />
      <Image src={logo.primary.medium.svg} />
    </Flex>
  );
};
